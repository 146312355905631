$imgPath: '/assets/all-in-one-theme/images';
$fontPath: '/assets/all-in-one-theme/fonts';
$themeVariantImgPath: '/assets/all-in-one-theme/images/theme-variants/bringo-romania-theme';
$colors: ('primary': red, 'color_1': #4A397E, 'color_2': #FFB600, 'color_3': #2DA771, 'color_4': #6D6D6D, 'color_5': #F42626, 'color_6': #EAB818, 'color_7': #FAB72C, 'color_8': #F5F5F5, 'color_9': #E9B818, 'color_10': #362768, 'color_11': rgba(74, 57, 126, 0.85), 'color_12': rgba(74, 57, 126, 1), 'color_13': #FFB600, 'color_14': #FFB600, 'color_15': #362768, 'color_16': #273C51);
$header: ('logo_img': 'bringo-logo.svg', 'logo_img_mobile': 'bringo-logo.svg', 'logo_width': 146px, 'logo_height': 46px, 'logo_width_mobile': 120px, 'logo_height_mobile': 38px, 'nav_width': 125px, 'nav_height': 40px, 'nav_margin_top': 0, 'nav_bg_img': 'bringo-logo.svg', 'hamburger_position_top': 27px);
$units: ('hamburger_menu_icon_top': 10px, 'nav_center_column_mt': 0, 'logo_width': 154px);
$fonts: ('default': 'Manrope');header {
    background-color: #FFFFFF;
}
nav.header-navigation {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;
}
nav.header-navigation .left-column {
    display: flex;
    margin-right: auto;
    align-items: center;
}
nav.header-navigation .right-column {
    display: flex;
    margin-left: auto;
    align-items: center;
}
nav.header-navigation a.logo {
    display: inline-flex;
}
nav.header-navigation .site-logo {
    background-image: url($imgPath + '/' + map-get($header, 'logo_img'));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: map-get($header, 'logo_width');
    height: map-get($header, 'logo_height');
    display: flex;
    margin-right: 30px;
}
nav.header-navigation .shopping-cart-icon {
    background-image: url($themeVariantImgPath + '/menu/shopping-cart-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    display: flex;
    margin-right: 10px;
}
nav.header-navigation .cart .badge-items {
    display: inline-flex;
    margin-right: 2px;
    background: map-get($colors, 'color_6');
    border-radius: 100%;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: map-get($colors, 'color_1');
}
nav.header-navigation .cart .badge-items.big {
    width: 25px;
    height: 25px;
}
nav.header-navigation .cart .cart-button {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 30px;
}
nav.header-navigation .favorite a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none!important;
    margin-left: 30px;
}
nav.header-navigation .favorite .favorite-icon {
    background-image: url($themeVariantImgPath + '/menu/favorite-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    display: flex;
    margin-right: 10px;
}
nav.header-navigation .favorite .badge-items {
    display: inline-flex;
    margin-right: 2px;
    background: map-get($colors, 'color_9');
    border-radius: 100%;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: map-get($colors, 'color_1');
}
nav.header-navigation .favorite .badge-items.big {
    width: 25px;
    height: 25px;
}
nav.header-navigation .user-avatar-icon {
    background-image: url($themeVariantImgPath + '/menu/user-avatar-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    display: flex;
    margin-right: 10px;
}

nav.header-navigation .active-orders-dropdown .active-order-icon {
    background-image: url($themeVariantImgPath + '/menu/active-order-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    display: flex;
    margin-right: 5px;
}
nav.header-navigation .active-orders-dropdown .badge-items {
    display: inline-flex;
    margin-left: 6px;
    background: map-get($colors, 'color_1');
    border-radius: 100%;
    width: 19px;
    height: 19px;
    align-items: center;
    justify-content: center;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #FFFFFF;
}
nav.header-navigation .active-orders-dropdown .badge-items.big {
    width: 25px;
    height: 25px;
}
nav.header-navigation .active-orders-dropdown > button.dropdown-toggle:after {
    background-image: url($themeVariantImgPath + '/menu/arrow-dropdown-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 8px;
    height: 5px;
    display: flex;
    margin-left: 10px;
    border: 0!important;
    position: relative;
    top: 2px;
    -webkit-transition: 500ms ease;
    -moz-transition: 500ms ease;
    transition: 500ms ease;
}
nav.header-navigation .active-orders-dropdown.show > button.dropdown-toggle:after,
nav.header-navigation .active-orders-dropdown:hover > button.dropdown-toggle:after {
    background-image: url($themeVariantImgPath + '/menu/arrow-dropdown-icon.svg');
}
nav.header-navigation .active-orders-dropdown > button.dropdown-toggle {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    box-shadow: none!important;
    padding: 0;
    background: transparent!important;
    border: 0;
    display: flex;
    align-items: center;
    margin-left: 30px;
}
nav.header-navigation .active-orders-dropdown.show > button.dropdown-toggle:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 500ms ease;
    -moz-transition: 500ms ease;
    transition: 500ms ease;
}
nav.header-navigation .active-orders-dropdown .dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 0;
    border: 0;
    margin-top: 15px;
    width: auto;
    left: auto!important;
    right: 7px;
}
nav.header-navigation .active-orders-dropdown .dropdown-menu:after, nav.header-navigation .active-orders-dropdown .dropdown-menu:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

nav.header-navigation .active-orders-dropdown .dropdown-menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
    right: 22px;
}
nav.header-navigation .active-orders-dropdown .dropdown-menu:before {
    border-bottom-color: rgba(0, 0, 0, 0.05);
    border-width: 7px;
    margin-left: -7px;
    right: 20px;
}
nav.header-navigation .active-orders-dropdown .dropdown-menu ul li {
    padding: 13px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    justify-content: center;
}
nav.header-navigation .active-orders-dropdown .dropdown-menu ul li:last-child {
    border: 0;
}
nav.header-navigation .active-orders-dropdown .dropdown-menu ul li a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    text-decoration: none!important;
    display: flex;
    white-space: nowrap;
}
nav.header-navigation .bringo-my-account > button.dropdown-toggle:after {
    background-image: url($themeVariantImgPath + '/menu/arrow-dropdown-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 8px;
    height: 5px;
    display: flex;
    margin-left: 10px;
}
nav.header-navigation .bringo-my-account.show > button.dropdown-toggle:after,
nav.header-navigation .bringo-my-account:hover > button.dropdown-toggle:after {
    background-image: url($themeVariantImgPath + '/menu/arrow-dropdown-icon.svg');
}
nav.header-navigation .bringo-my-account > button.dropdown-toggle {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    box-shadow: none!important;
    padding: 0;
    background: transparent!important;
    border: 0;
    display: flex;
    align-items: center;
    margin-left: 30px;
}
nav.header-navigation ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: map-get($header, 'nav_margin_top');
}
nav.header-navigation ul li {
    display: inline-flex;
    padding-right: 30px;
}
nav.header-navigation ul li a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1');
    text-decoration: none!important;
    position: relative;
}
nav.header-navigation ul li.active a:after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    background: map-get($colors, 'color_6');
    width: 27px;
    height: 3px;
}
nav.header-navigation ul li.active a {
    color: map-get($colors, 'color_6');
}
nav.header-navigation .dropdown.active-orders-dropdown {
    margin-top: map-get($header, 'nav_margin_top');
}
nav.header-navigation .bringo-my-account .dropdown-menu {
    padding: 0;
    border: 0;
    background: #FFFFFF;
    box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    width: auto;
    margin-top: 15px;
    right: 9px;
    left: auto!important;
}
nav.header-navigation .bringo-my-account .dropdown-menu .login-div {
    border-radius: 15px 15px 0 0;
    background: map-get($colors, 'color_1');
    padding: 20px;
}
nav.header-navigation .bringo-my-account .dropdown-menu .login-div a {
    background:  map-get($colors, 'color_6');
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF!important;
    padding: 13px 40px;
    text-decoration: none!important;
    display: flex;
    white-space: nowrap;
}
nav.header-navigation .bringo-my-account .dropdown-menu .create-account-div {
    padding: 15px 20px;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color:  map-get($colors, 'color_4');
}
nav.header-navigation .bringo-my-account .dropdown-menu .create-account-div .register-btn {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    text-decoration: none!important;
    display: inline-flex;
    margin-top: 8px;
}
nav.header-navigation .bringo-my-account .dropdown-menu .hello_fullname {
    border-radius: 15px 15px 0 0;
    background: map-get($colors, 'color_1');
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
    padding: 12px 20px;
    white-space: nowrap;
}
nav.header-navigation .bringo-my-account .dropdown-menu ul {
    padding: 5px 0;
}
nav.header-navigation .bringo-my-account .dropdown-menu ul li.logout-btn {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 10px;
    padding-bottom: 10px;
    margin-top: 10px;
}
nav.header-navigation .bringo-my-account .dropdown-menu ul li {
    display: flex;
    padding: 5px 20px;
}
nav.header-navigation .bringo-my-account .dropdown-menu ul li a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1');
    text-decoration: none!important;
    display: flex;
    white-space: nowrap;

    &.active {
        font-weight: 600;
    }
}
nav.header-navigation .bringo-my-account .dropdown-menu:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}
nav.header-navigation .bringo-my-account .dropdown-menu:before {
    border-color: transparent;
    border-bottom-color: map-get($colors, 'color_1');
    border-width: 5px;
    margin-left: -5px;
    right: 20px;
}
nav.header-navigation .alert-notifications {
    display: inline-block;
    position: relative;
    padding: 0;
    margin-left: 30px;
}
nav.header-navigation .alert-icon {
    background-image: url($themeVariantImgPath + '/alert-white-icon.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 24px;
    height: 20px;
    display: inline-block;
    position: relative;
    top: 2px;
    animation: ease-in-out alertNotifications 1.2s infinite;
    -moz-animation: ease-in-out alertNotifications 1.2s infinite;
    -webkit-animation: ease-in-out alertNotifications 1.2s infinite;
}
nav.header-navigation .alert-notifications .popup {
    position: absolute;
    display: flex;
    z-index: 1201;
    right: 0;
    width: 270px;
    padding: 12px 15px;
    border-radius: 10px;
    background: map-get($colors, 'color_9');
    text-align: center;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
    animation: alertNotificationsPopup 1.2s infinite;
    -moz-animation: alertNotificationsPopup 1.2s infinite;
    -webkit-animation: alertNotificationsPopup 1.2s infinite;
}
nav.header-navigation .alert-notifications .popup:after {
    bottom: 100%;
    right: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(250, 183, 44, 0);
    border-bottom-color: map-get($colors, 'color_9');
    border-width: 6px;
    margin-left: -6px;
}
nav.header-navigation .alert-notifications .popup p {
    font-size: 14px;
    font-weight: 500;
    color: #FFFFFF;
    margin: 0;
}
@keyframes alertNotifications {
    0% {background-image: url($themeVariantImgPath + '/alert-white-icon.png');}
    50% {background-image: url($themeVariantImgPath + '/alert-white-hover-icon.png');}
    100% {background-image: url($themeVariantImgPath + '/alert-white-icon.png');}
}
@-webkit-keyframes alertNotifications {
    0% {background-image: url($themeVariantImgPath + '/alert-white-icon.png');}
    50% {background-image: url($themeVariantImgPath + '/alert-white-hover-icon.png');}
    100% {background-image: url($themeVariantImgPath + '/alert-white-icon.png');}
}
@-moz-keyframes alertNotifications {
    0% {background-image: url($themeVariantImgPath + '/alert-white-icon.png');}
    50% {background-image: url($themeVariantImgPath + '/alert-white-hover-icon.png');}
    100% {background-image: url($themeVariantImgPath + '/alert-white-icon.png');}
}
@keyframes alertNotificationsPopup {
    0% {top: 39px;}
    50% {top: 44px;}
    100% {top: 39px;}
}
@-webkit-keyframes alertNotificationsPopup {
    0% {top: 39px;}
    50% {top: 44px;}
    100% {top: 39px;}
}
@-moz-keyframes alertNotificationsPopup {
    0% {top: 39px;}
    50% {top: 44px;}
    100% {top: 39px;}
}
nav.header-navigation .hamburger-menu-icon {
    top: 0;
    bottom: 0;
    margin: auto;
    left: 15px;
}
.hamburger-menu-mobile.big-menu .hamburger-menu-close-icon {
    top: map-get($header, 'hamburger_position_top');
    left: 15px;
}
.hamburger-menu-mobile.big-menu .site-logo {
    background-image: url($imgPath + '/' + map-get($header, 'logo_img_mobile'));
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: map-get($header, 'logo_width_mobile');
    height: map-get($header, 'logo_height_mobile');
    display: flex;
}
.hamburger-menu-mobile.big-menu .header .logo {
    top: 20px;
    left: 55px;
    right: auto;
    justify-content: flex-start;
    width: auto;
}
nav.header-navigation .search-mobile-icon {
    background-image: url($themeVariantImgPath + '/menu/search-mobile-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    display: none;
}

.hamburger-menu-mobile.big-menu .active-orders-dropdown {
    position: absolute;
    right: 15px;
    top: 25px;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-toggle {
    background: transparent;
    border: 0;
    font-size: 0;
    padding: 0;
    display: flex;
    align-items: center;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-toggle .badge-items {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: right;
    color: map-get($colors, 'color_1');
    width: 19px;
    height: 19px;
    background-color: #FFFFFF;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .active-order-icon {
    background-image: url($themeVariantImgPath + '/menu/active-order-white-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 22px;
    height: 22px;
    display: flex;
    margin-right: 5px;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown > button.dropdown-toggle:after {
    background-image: url($themeVariantImgPath + '/menu/arrow-dropdown-white-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    display: flex;
    margin-left: 5px;
    border: 0!important;
    position: relative;
    top: 0;
    -webkit-transition: 500ms ease;
    -moz-transition: 500ms ease;
    transition: 500ms ease;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown.show > button.dropdown-toggle:after,
.hamburger-menu-mobile.big-menu .active-orders-dropdown:hover > button.dropdown-toggle:after {
    background-image: url($themeVariantImgPath + '/menu/arrow-dropdown-white-icon.svg');
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown.show > button.dropdown-toggle:after {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    -webkit-transition: 500ms ease;
    -moz-transition: 500ms ease;
    transition: 500ms ease;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu {
    background: #FFFFFF;
    box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.15), 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    padding: 0;
    border: 0;
    margin-top: 15px;
    width: auto;
    left: auto!important;
    right: -15px;
    transform: translate3d(0px, 22px, 0px) !important;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu:after, .hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu:before {
    bottom: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu:after {
    border-color: rgba(255, 255, 255, 0);
    border-bottom-color: #fff;
    border-width: 5px;
    margin-left: -5px;
    right: 20px;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu:before {
    border-bottom-color: rgba(0, 0, 0, 0.05);
    border-width: 7px;
    margin-left: -7px;
    right: 18px;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu ul {
    margin: 0;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu ul li {
    padding: 13px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    justify-content: center;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu ul li:last-child {
    border: 0;
}
.hamburger-menu-mobile.big-menu .active-orders-dropdown .dropdown-menu ul li a {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_1')!important;
    text-decoration: none!important;
    display: flex;
    white-space: nowrap;
}

.bringo-subheader-section {
    padding: 20px 0;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.15), 0px 2px 2px rgba(0, 0, 0, 0.15);
}
.bringo-subheader-section > .container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}
.bringo-subheader-section .left-column,
.bringo-subheader-section .right-column {
    display: flex;
}
.bringo-subheader-section .left-column {
    @media (max-width: 1259px) {
        margin-bottom: .25rem;
    }
}
.bringo-subheader-section .logo-selected-shop {
    display: flex;
    align-items: center;
}
.bringo-subheader-section .logo-selected-shop img {
    max-width: 70px;
    max-height: 56px;
}
.bringo-subheader-section .status-program-shop .open-store-status-icon {
    background-image: url($themeVariantImgPath + '/icons/open-store-status-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 10px;
}
.bringo-subheader-section .status-program-shop .close-store-status-icon {
    background-image: url($themeVariantImgPath + '/icons/close-store-status-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 20px;
    height: 20px;
    display: flex;
    margin-right: 10px;
}
.bringo-subheader-section .status-program-shop {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_4');
    display: flex;
    align-items: center;
}
.bringo-subheader-section .status-program-shop span {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: map-get($colors, 'color_3');
    margin-right: 10px;
    display: inline-flex;
}
.bringo-subheader-section .big-search input.form-control,
.bringo-subheader-section .store-search input.form-control {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 15px;
    padding: 18px 20px;
    height: 56px;
    width: 500px;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: map-get($colors, 'color_4');
}
.bringo-subheader-section .big-search button,
.bringo-subheader-section .store-search button {
    position: absolute;
    right: 0;
    top: 0;
    height: 56px;
    border: 0;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 59px;
    background-color: map-get($colors, 'color_13');
    box-shadow: none!important;
    outline: none!important;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}
.bringo-subheader-section .big-search button .search-big-icon,
.bringo-subheader-section .store-search button .search-big-icon {
    background-image: url($themeVariantImgPath + '/icons/search-big-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 24px;
    height: 24px;
    display: inline-flex;
}
.bringo-subheader-section .big-search,
.bringo-subheader-section .store-search {
    display: inline-block;
    float: left;
    position: relative;
    z-index: 1200;
    margin-left: 20px;
    border: 0;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}
.bringo-subheader-section .big-search-results {
    position: absolute;
    display: block;
    visibility: hidden;
    opacity: 0;
    top: 56px;
    left: 0;
    padding-top: 0;
    background-color: #FFFFFF;
    width: 500px;
    z-index: -1;
    border-radius: 0 0 15px 15px;
    transform: translateY(40px);
    -webkit-transform: translateY(40px);
    -moz-transform: translateY(40px);
    -ms-transform: translateY(40px);
    -o-transform: translateY(40px);
    -webkit-transition: 500ms cubic-bezier(.22,.43,.35,.83);
    -moz-transition: 500ms cubic-bezier(.22,.43,.35,.83);
    transition: 500ms cubic-bezier(.22,.43,.35,.83);
}
.bringo-subheader-section .big-search-results .body-big-search-results {
    max-height: 370px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px 0;
}
.bringo-subheader-section .big-search-results .category-div {
    padding:5px 20px;
}
.bringo-subheader-section .big-search-results .body-big-search-results .product-img-thumbnail {
    padding: 12px 0;
    width: 85px;
    text-align: center;
    display: inline-block;
    float: left;
}
.bringo-subheader-section .big-search-results .body-big-search-results::-webkit-scrollbar {
    display: none;
}
.bringo-subheader-section .big-search-results .body-big-search-results .product-img-thumbnail a {
    display: inline-block;
}
.bringo-subheader-section .big-search-results .body-big-search-results .product-img-thumbnail img {
    max-width: 45px;
    max-height: 45px;
}
.bringo-subheader-section .big-search-results .body-big-search-results .product-title-price {
    padding: 12px 0;
    display: inline-block;
    float: left;
}
.bringo-subheader-section .big-search-results .body-big-search-results .product-title-price h3 a {
    font-size:12px;
    color:map-get($colors, 'color_1');
    margin:0;
    line-height: normal;
}
.bringo-subheader-section .big-search-results .body-big-search-results .category-div .title-category {
    font-weight: 400;
    line-height: normal;
    font-size: 12px;
    color: map-get($colors, 'color_1');
}
.bringo-subheader-section .big-search-results .body-big-search-results .category-div a.view-category {
    font-weight: 400;
    line-height: normal;
    font-size: 12px;
    color: #666666;
    text-decoration: none!important;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out, border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}
.bringo-subheader-section .big-search-results .body-big-search-results li.product-line,
.bringo-subheader-section .big-search-results .body-big-search-results li.category-line {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
}
.bringo-subheader-section .big-search-results .body-big-search-results .category-div a.view-category:hover {
    color: map-get($colors, 'color_9');
}
.bringo-subheader-section .big-search-results .body-big-search-results .product-title-price span.price {
    font-weight: 500;
    line-height: normal;
    font-size: 14px;
    color: #D02626;
    margin-top:4px;
    display: block;
}
.bringo-subheader-section .big-search-results .bottom-big-search-results .view-all-results-btn {
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none!important;
    padding: 14px 10px;
    width: 100%;
    display: block;
    text-align: center;
    position: relative;
    background: map-get($colors, 'color_1');
    border-radius: 0px 0px 15px 15px;
}
.bringo-subheader-section .big-search-results .bottom-big-search-results .view-all-results-btn:after {
    content: "";
    position: absolute;
    top: -25px;
    height: 25px;
    left: 0;
    right: 0;
    background-image: -webkit-linear-gradient(top,transparent 0,rgba(0,0,0,.15) 100%);
    background-image: -o-linear-gradient(top,transparent 0,rgba(0,0,0,.15) 100%);
    background-image: linear-gradient(to bottom,transparent 0,rgba(0,0,0,.15) 100%);
    background-repeat: repeat-x;
    z-index: 1;
}
.bringo-subheader-section .big-search .close-btn {
    display: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 56px;
    border: 0;
    padding: 0;
    background-color: map-get($colors, 'color_14');
    box-shadow: none!important;
    outline: none!important;
    border-radius: 0 15px 15px 0;
    width: 60px;
    align-items: center;
    justify-content: center;
}
.bringo-subheader-section .big-search .close-btn .close-icon {
    background-image: url($imgPath + '/close-cart-white-icon.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    display: inline-block;
}
.bringo-subheader-section .big-search.open-search input.form-control {
    border-bottom-left-radius: 0!important;
    border-bottom-right-radius: 0!important;
}
.bringo-subheader-section .big-search.open-search button {
    border-bottom-right-radius: 0!important;
}
.bringo-slogan h1 {
    margin-top: 20px;
    margin-bottom: 20px;
    font-family: map-get($fonts, 'default');
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    text-align: center;
    color: map-get($colors, 'color_6');
    position: relative;
    z-index: 5;
}

.bringoRomania-theme .bringo-slogan h1 {
    margin: 0!important;
}

@media (max-width: 1260px) {
    nav.header-navigation {
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .bringo-subheader-section {
        padding: 15px 0;
    }
    .bringo-subheader-section .status-program-shop {
        font-size: 14px;
        line-height: 17px;
    }
    .bringo-subheader-section .status-program-shop span {
        font-size: 14px;
        line-height: 17px;
    }
    nav.header-navigation .site-logo {
        width: map-get($header, 'logo_width_mobile');
        height: map-get($header, 'logo_height_mobile');
        margin-right: 15px;
        background-image: url($imgPath + '/' + map-get($header, 'nav_bg_img'));
    }
    nav.header-navigation ul li {
        padding-right: 20px;
    }
    nav.header-navigation ul li a {
        font-size: 15px;
    }
    nav.header-navigation .active-orders-dropdown > button.dropdown-toggle {
        margin-left: 0;
        font-size: 15px;
    }
    nav.header-navigation .favorite a {
        margin-left: 20px;
        font-size: 15px;
    }
    nav.header-navigation .bringo-my-account > button.dropdown-toggle {
        margin-left: 20px;
        font-size: 15px;
    }
    nav.header-navigation .cart .cart-button {
        margin-left: 20px;
        font-size: 15px;
    }
    nav.header-navigation .alert-notifications {
        margin-left: 20px;
    }
}
@media (max-width: 992px) {
    nav.header-navigation .search-mobile-icon {
        display: flex;
    }
    nav.header-navigation a.logo {
        margin-left: 40px;
    }
    nav.header-navigation .hamburger-menu-icon {
        display: flex;
    }
    nav.header-navigation .site-logo {
        width: map-get($header, 'logo_width_mobile');
        height: map-get($header, 'logo_height_mobile');
        margin-right: 0;
    }
    nav.header-navigation ul {
        display: none;
    }
    nav.header-navigation .active-orders-dropdown,
    nav.header-navigation .bringo-my-account {
        display: none;
    }
    nav.header-navigation .favorite a,
    nav.header-navigation .cart .cart-button {
        font-size: 0;
        line-height: 0;
    }
    nav.header-navigation .favorite .favorite-icon,
    nav.header-navigation .shopping-cart-icon {
        margin: 0;
    }
    .bringo-subheader-section .big-search.mobile-search-open input.form-control {
        width: 100%;
        height: 56px;
    }
    .bringo-subheader-section .big-search {
        display: none;
    }

    body.stores-list nav.header-navigation .left-column {
        margin-right: 0;
        width: 100%;
        justify-content: center;
    }
    body.stores-list nav.header-navigation a.logo {
        margin-left: 0!important;
    }
    .bringo-slogan h1 {
        font-size: 36px;
        line-height: 46px;
        margin-top: 10px;
        margin-bottom: 0;
    }
}
@media (max-width: 767px){
    nav.header-navigation .alert-notifications {
        display: none;
    }
    .bringo-subheader-section {
        padding: 10px 0;
    }
}
